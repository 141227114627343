html, body, .body {
  min-height: 100%;
  font-family: 'Rubik', sans-serif !important;
  color: #483E99 !important;
  overflow-x: hidden;

  // .navbar-toggler {
  //   visibility: hidden;
  // }

  .w-tab-content{
    width: 100%;
    overflow-x: auto;
  }

  .logged-in-div{
    height: 60px !important;
  }

  .login-form-container{
    margin-top: 60px;
    padding-top: 60px;
    max-width: 350px;
    margin: auto;
    
    .input-group-btn{
      position: relative;

      input{
        padding-right: 36px;
      }

      button{
        position: absolute;
        right: 0px;
        top: 6px;
      }
    }
    .header{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 16px;
      margin-bottom: 24px;
    
      strong{
        color: #483E99;
        font-size: 24px;
      }
    
      a{
        color: #483E99;
        text-decoration: none;
        font-weight: bold;
        padding: 8px 16px;
        border: 2px solid #483E99;
        border-radius: 4px;
        transition: all 0.2s ease-in;
      }
    }
  }

.sobre-o-evento-container{
  padding-top: 58px;
  background: #FFF;
  margin-top: -61px;
  padding-left: 10%;
  padding-right: 10%;

  @media screen and (max-width: 767px) {
    margin-top: -38px;
  }
  
  .very-big-purple-text{
    padding-top: 58px;
    color: #483E99;
    font-family: Rubik;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    max-width: 583px;
    margin: auto;
    margin-left: 0;
    margin-bottom: 58px;
  }
  .very-big-purple-text2{
    color: #483E99;
    font-family: Rubik;
    font-size: 59px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 140px;
    width: 100%;
    max-width: 495px;
  }

  .smaller-text{
    color: #483E99;
    font-family: Rubik;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 100%;
    max-width: 583px;
    margin: auto;
    margin-left: 0;
    margin-bottom: 80px;
  }

  .gray-square{
    border-radius: 18px;
    background: #E4EAF0;
    width: 100%;
    max-width: 499px;
    height: 475px;
    flex-shrink: 0;
    margin: auto;
    margin-bottom: 40px;
    margin-right: 0;
  }

  .gray-rectangle{
    width: 100%;
    max-width: 610px;
    height: 411px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #D9D9D9;
    text-align: center;
    margin-bottom: 90px;
    margin: auto;
    margin-right: 0;
  }

    .container-divider{
      margin-bottom: 100px;
  
      &.left{
        @media screen and (min-width: 767px) {
          border-left: 6.5px solid #ECF2F8;
        }
      }
  
      // &.right{
      //   @media screen and (min-width: 767px) {
      //     border-right: 6.5px solid #ECF2F8;
      //   }
      // }
    }

  .title-number-text{
    color: #483E99;
    text-align: center;
    font-family: Rubik;
    font-size: 41.862px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .subtitle-number-text{
    color: #483E99;
    text-align: center;
    font-family: Rubik;
    font-size: 24.903px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .aspas-amarela{
    margin-bottom: 31px;
  }
  .video-player-icon{
    text-align: center;
    display: flex;
    height: 100%;
    
    img{
      margin: auto;
    }
  }
}

.sobre-o-evento-gallery-container{
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 45px;
  
  .gallery-container-row{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 17px;
  }

  .gallery-item{
    border-radius: 18px;
    background: #E4EAF0;
    width: 100%;
    max-width: 270px;
    height: 181px;
    flex-shrink: 0;
    margin: auto;
    // margin-left: 5px;
    // margin-right: 5px;
    margin-bottom: 17px;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
      
    img {
      flex-shrink: 0;
      min-width: 140%;
      min-height: 140%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.big-text{
  color: #483E99;
  text-align: center;
  font-family: Rubik;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

// .button-container{
//   width: 206px;
//   margin-bottom: 57px;
// }

.button-container{
  width: 206px;
  text-transform: uppercase;

  &.admin{
    width: 144px !important;
    text-transform: none;
  }
}

.programacao-block{
  padding-top: 37px;
  padding-bottom: 71px;
  padding-left: 10%;
  padding-right: 10%;

  &.gray{
    background: #F6F6F6;
  }
}

.programacao-big-text{
  color: #483E99;
  text-align: center;
  font-family: Rubik;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 160px;
}

.sobre-container{
  padding-left: 20%;
  padding-right: 10%;
  margin-top: 103px;
  margin-bottom: 41px;

  .big-red-text{
    color: #FF4D41;
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .bigger-purple-text{
    color: #483E99;
    font-family: Rubik;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 7px;
  }

  .underline-purple-text{
    border-radius: 41px;
    background: #483E99;
    border-top: 9px solid #483E99;
    width: 59px;
    margin-bottom: 27px;
  }

  .clock-container{
    border-radius: 12px;
    background: #EFEFEF;
    // width: 178px;
    width: 100%;
    max-width: 245px;
    flex-shrink: 0;
    padding: 14px 8px;
    margin-bottom: 20px;

    .top-part{
      color: #000;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 8px;
    }

    .bottom-part{
      color: #FF3633;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      justify-content: flex-start;

      .date-time-part{
        border-radius: 8px;
        border: 1px solid #FF3633;
        margin-right: 8px;
        padding: 8px 9px;
      }
    }
  }

  .top-section-text{
    color: #483E99;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
    width: 100%;
    max-width: 541px;
  }
}

.small-text{
  &.yellow{
    color: #FFC200 !important;
  }

  color: #483E99;
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

  .heading-3 {
    font-size: 26px !important;
    line-height: 24px !important;
    margin-top: 10px !important;
    font-weight: bolder !important;
    margin-bottom: 30px !important;
  }

  .div-line-point{
    margin-top: 10px !important;
    margin-bottom: 19px !important;
    color: #262626;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
  }

.palestrante-bio{
  padding-top: 32px;
}

  .text-block-15 {
    padding-top: 8px;
    padding-left: 6px;
    img{
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
    border-radius: 0px 30px;
    background: #FF3633;
    height: 39px;
    width: 199px;

    color: #FFFFFF;
    text-align: center;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.16px;
    margin-bottom: 21px;

    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  .text-block-16 {
    padding-top: 52px;
    // color: #483E99;
    font-size: 24px;
    line-height: 29px;
    font-weight: normal;
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: auto;

    a{
      max-height: 34px;
      max-width: 194px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      text-align: center;
    }

    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

}
.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}
.menu {
  height: 60px;
}

.container {
  // max-width: 1140px;
  max-width: 90%;

  &.smaller{
    max-width: 85%;
  }
}
.nav-menu {
  position: relative;
  float: left;
}
.nav-link {
  font-family: Rubik;
  color: #483E99;
  
  &:hover {
    color: #483E99;
  }

  &.active{
    font-weight: bolder;
    color: #483E99 !important;
  }
  font-size: 16px;
}
.body {
  font-family: Rubik;
  color: #000000;
}
.navbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 6px 3px 0 rgba(0, 0, 0, 0.15);
  color: #fff;

  &.admin{
    background: #483E99 !important;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.20) !important;
  }
}
.container-2 {
  max-width: 1140px;
  height: 60px;
}

.nav-link-2 {
  color: #483E99;
}
.nav-link-2:hover {
  color: #483E99;
}
.nav-link-3 {
  color: #483E99;
}
.nav-link-3:hover {
  color: #483E99;
}
.nav-link-4 {
  color: #483E99;
}
.nav-link-4:hover {
  color: #483E99;
}
.nav-link-5 {
  color: #483E99;
}
.nav-link-5:hover {
  color: #483E99;
}
.button {
  // display: block;
  // width: 100%;
  width: auto;
  border: 1px solid #FFC200;
  border-radius: 30px;
  background-color: #FFC200;
  color: #FFF;
  font-size: 16px;
  font-weight: normal !important;
  white-space: nowrap;
  &:hover{
    color: #FFF !important;
  }
}
.div-block {
  max-width: 300px;
  min-width: 150px;
  margin-top: 10px;
  margin-left: 8px;
  float: right;
  text-align: center;
}
.realtor-name {
  width: 100%;
  // border: 2px solid #FFC200;
  // border-radius: 30px;
  // background-color: #fff;
  color: #070707;
  font-size: 16px;
  font-weight: 700;
  @media screen and (min-width: 767px) {
    display: none;
  }
}
.button-2 {
  width: 100%;
  border: 2px solid #FFC200;
  border-radius: 30px;
  background-color: #fff;
  color: #070707;
  font-size: 16px;
  font-weight: 700;
}
.div-block-2 {
  max-width: 400px;
  margin-top: 90px;
  margin-right: auto;
  margin-left: auto;
}
.div-block-2-logada {
  max-width: 400px;
  margin-top: 90px;
}
.footer-image{
  min-height: 70px;
  height: auto;
  // height: auto;
  background-image: url('../src/assets/images/landing-page/footer-image.svg');
  background-position: 0px 0px;
  background-size: cover;
}
.principal {
  min-height: 521px;
  margin-top: 75px;
  
  background-image: url('../src/assets/images/2024/banner_saber.jpg');
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 767px) {
    background-size: contain;
    background-position: center;
    
    &.sobre-o-evento{
      margin-top: -17px;
    }
  }
} 
// .principal2 {
//   min-height: 400px;
//   margin-top: 60px;
//   background-image: url('../src/assets/images/landing-page/Background3.png');
//   background-position: 0px 0px;
//   background-size: cover;
// }
.container-3 {
  max-width: 1140px;
  padding-top: 60px;
  padding-bottom: 30px;
}
.heading {
  margin-top: 0px;
  color: #483E99;
  text-align: center;
  font-family: Rubik;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text-block {
  // max-width: 570px;
  max-width: 801px;
  margin-right: auto;
  margin-left: auto;
  color: #483E99;
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.black{
    color: #262626;
  }
}
.text-block2 {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: #483E99;
  text-align: center;
  font-family: Rubik;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}
.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.div-line-point{
 margin-top: 10px;
}
.div-line-point-2 {
 margin-top: 20px;
 margin-left: 20px;
}
.image {
 position: absolute;
 left: auto;
 top: 0%;
 right: 24px;
 bottom: 0%;
 z-index: -2;
 margin-top: 1407px;
 float: right;
 width: 56px;
 transform: rotate(270deg);
}
.div-separator {
height: 130px;
 float: left;
 border: 1px solid #ffff;
 width: 1px;
 padding: 0px;
}
.margin-footer {
margin-left: 110px;
}
.container-4 {
  max-width: 640px;
  line-height: normal;
}
.heading-2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
}
.tab-pane-tab-1 {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f3f3f3;
}
.tab-link-tab-1 {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #cacaca;
}
.tab-link-tab-1.w--current {
  border-width: 2px 2px 0px;
  border-radius: 5px 5px 0px 0px;
  background-color: #483E99;
}
.tab-link-tab-2 {
  margin-left: 10px;
  border-width: 2px 2px 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #cacaca;
}
.tab-link-tab-3 {
  margin-left: 10px;
  border-width: 2px 2px 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #cacaca;
}
.div-block-3 {
  margin-right: auto;
  margin-left: auto;
}
.text-block-2 {
  font-size: 16px;
  line-height: 1.5;
}
.div-block-4 {
  padding-right: 64px;
  padding-top: 38px;
  
  @media screen and (min-width: 767px) {
    padding-left: 42.4px;
  }
}
.columns {
  border-width: 0px 2px 2px;
}
.text-block-3 {
  color: #262626;
}
.tab-link-tab-4 {
  margin-left: 12px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #cacaca;
}
.tab-link-tab-5 {
  margin-left: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #cacaca;
}
.tab-pane-tab-2 {
  padding-top: 29px;
  padding-bottom: 29px;
  background-color: #f3f3f3;
}
.tab-pane-tab-3 {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f3f3f3;
}
.tab-pane-tab-4 {
  padding-top: 29px;
  padding-bottom: 29px;
  background-color: #f3f3f3;
}
.tab-pane-tab-5 {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f3f3f3;
}
.tabs {
  margin-top: 40px;
}
.tab-pane-tab-1-2 {
  padding: 30px;
  // background-color: #fff;

  &.admin{
    padding: 0px !important;
  }
}
.tab-pane-tab-2-2 {
  padding: 30px;
  // background-color: #fff;
}
.tab-pane-tab-3-2 {
  padding: 30px;
  // background-color: #fff;
}
.tab-pane-tab-4-2 {
  padding: 30px;
  // background-color: #fff;
}
.tab-pane-tab-5-2 {
  padding: 30px;
  // background-color: #fff;
}
.tab-link-space-25{
//  width: 25%;
 float: left;
}
.tab-link-space-25-2{
  // width: 25%;
  float: left;
  border: none;
  background: white;
  color: white;
}
.tab-link-space-29{
//  width: 29%;
 float: left;
}
.tab-link-space-21{
//  width: 21%;
 float: left;
}
/*.tab-link-01-12 {
  margin-left: 173px;
}
}
.tab-link-02-12 {
  margin-left: 126px;
}
.tab-link-03-12 {
  margin-left: 129px;
}
.tab-link-04-12 {
  margin-left: 10px;
}*/
.tabs-2 {
  margin-top: 50px;
}
.float_left {
   position: absolute;
   z-index: -1;
   width: 84px;
   margin-top: -41px;
   margin-left: -47px;
}
.text-block-4 {
  font-size: 16px;
  text-align: center;
}
.cadastro {
  max-width: 600px;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  border-radius: 20px;
  background-color: #483E99;
}
.section {
  display: block;
  padding-top: 40px;
  padding-bottom: 40px;
}
.container-5 {
  max-width: 960px;
}
.container-6 {
  max-width: 1140px;
}
.creci_parceiro {
  position: relative;
  width: 150px;
  margin-left: 10px;
  float: left;
}
.div-block-7 {
  margin-top: 40px;
}
.columns-2 {
  margin-top: 40px;
}
.depoimentos {
  min-height: 230px;
  padding: 30px;
  border-radius: 20px;
  background-color: #f6f6f6;
  margin-top: 20px;
}
.videos-background {
  min-height: 350px;
  border-radius: 20px;
}
.under-video-text{
  margin-top: 19px;
  color: #483E99;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.18px;
  width: 100%;
  max-width: 433px;
}

.videos-palestrantes {
  margin-top: 25px;
 color: #070707;
 font-size: 30px;
}
.section-2 {
  margin-top: 40px;
}

.admin-tab-content{
  border-top: 3px solid #FFC200 !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.w-container-section-2 {
  margin-top: 40px;
  padding-left: 38px;
  padding-right: 38px;
  padding-top: 49px;
  border: none;
  border-radius: 20px;

  &.admin{
    margin-top: 0;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    background: #FFF;
    // gap: 24px;

    .div-table-cell{
      margin-left: 0;
      margin-right: 0;
      padding-top: 30px;

      &.admin{
        margin-left: unset;
        margin-right: unset;
        width: 100%;
      }

      .formgroup-inline{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        position: relative;
        
        img{
          position: absolute;
          top: 10px;
          left: 170px;
        }

        .label{
          color: #483E99;
          text-align: center;
          font-family: Rubik;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        input{
          margin-left: 32px !important;
          width: 182px;
          padding: 17px 25px;
          padding-left: 50px;
          align-items: center;
          gap: 8px;
        }
      }

      .formgroup-search{
        margin-bottom: 46px;
        position: relative;
        
        input{
          padding-left: 50px;
        }

        img{
          position: absolute;
          top: 10px;
          left: 20px;
        }
      }
    }
  }

  input::placeholder {
    color: #262626;
  }

  input, select {
    background: #FAFAFF 0% 0% no-repeat padding-box;
    padding-left: 25px;
    border: none;
    background: #F6F6F6;
    border-radius: 30px;
    border-radius: 30px;
    opacity: 1;
    height: 43px;
    max-width: 479px;
    color: #262626;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color:    #262626;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color:    #262626;
      opacity:  1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color:    #262626;
      opacity:  1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color:    #262626;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
      color:    #262626;
    }
    
    ::placeholder { /* Most modern browsers support this now. */
      color:    #262626;
    }
  }

  select{
    background-image: linear-gradient(45deg, transparent 50%, #FFC200 50%), linear-gradient(135deg, #FFC200 50%, transparent 50%),;
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    -webkit-appearance: none;
  }
}
.form-subscribe{
  // margin-top: 30px;
  // background-color: #483E99;
  // border-radius: 18px;
  padding-top: 35px;
  padding-bottom: 35px;
}
.section-3 {
  margin-top: 40px;
}
.section {
  margin-top: 10px;
}
.img-hugging{
  text-align: center;
  padding-top: 40px;
  img{
    margin: auto;
  }
}
.section-4 {
  // margin-top: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #483E99;
}
.container-7 {
  max-width: 1140px;
}
.container-7 {
  max-width: 1140px;
}
.text-block-5 {
  color: #483E99;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.section-5 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.text-block-6 {
  margin-top: 0px;
  color: #fff;
}
.div-block-9 {
  position: relative;
  float: left;
}
.link {
  margin-top: 10px;
  float: left;
  clear: both;
  color: #fff;
  text-decoration: none;
}
.css-input{
  padding: 5px 0px 6px 11px;
  font-size: 16px;
  background-color: #FFFFFF;
  color: #000000;
  border-radius: 33px;
  border-width: 0px;
}

.css-input.name-user{
  width: 406px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.css-input.cpf-user{
  width: 290px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.css-input.cnpj-user{
  width: 411px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.css-input.creci-user{
  width: 126px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.css-input.email-user{
  width: 295px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.css-input.email-user2{
  width: 295px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.css-input.pass-user{
  width: 222px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.css-input.pass2-user{
  width: 222px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.css-input.phone-user{
  width: 290px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.css-input.business-name-user{
  width: 274px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.css-input.role-user{
  width: 295px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.css-input.zipcode-user{
  width: 250px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.css-input.city-user{
  width: 290px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.css-input.state-user{
  width: 145px;
  margin-right: 8px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.css-input.isCorretor-user{
  width: 265px;

  @media screen and (max-width: 1130px) {
    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
// .cityDiv{
//   width: 430px;
// }
// .stateDiv{
//   width: 430px;
// }
.css-input:focus {
  outline:none;
}
.day-box{
  max-width: 200px;
  border: 2px solid #ff4d41;
  border-radius: 9px;
  text-align: center;
}
.day-box .day{
  font-size: 60px;
  font-weight: bold;
  color: #483E99;
}
.day-box .month{
  font-size: 30px;
  color: #483E99;
}
.div-table{
  display: table;
  width: 100%;
  margin-top: 25px;
}
.div-table-body{
  display: table-row-group;
}
.div-table-row{
  display: flex;
  // justify-content: space-evenly;
  // align-items: flex-start;

  &.admin{
    justify-content: space-between;
  }
}
.div-table-cell{
  // display: table-cell;
  margin-right: 10px;
  margin-left: 10px;
}
.login {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  display: none;
  background-color: hsla(0, 0%, 4%, 0.8);
}
.div-block-10 {
  max-width: 500px;
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 20px;
  background-color: #fff;
}
.link-block {
  margin-top: -40px;
  float: right;
}
.text-block-7 {
  color: #070707;
  font-size: 30px;
  line-height: 1.5;
}
.div-block-11 {
  padding: 30px;
}
.field-label {
  color: #070707;
}
.field-label-2 {
  color: #070707;
}
.text-field {
  border-radius: 50px;
}
.text-field-2 {
  border-radius: 50px;
}

.pink-bg-text{
  background-color: #FF3633;
  // padding-top: 4px;
  // padding-bottom: 4px;
  padding: 12px 33px;
  color: #FFFFFF;
  text-align: center;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.submit-button {
  margin-top: 46px;
  margin-bottom: 70px;
  color: #FFF;
  font-weight: 500;
  font-size: 20px;
  width: 268px;
  height: 57px;
  background: #FFC300 0% 0% no-repeat padding-box;
  border-radius: 50px;
  opacity: 1;
  // padding: 48px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:disabled{
    background-color: gray;
    cursor: not-allowed;
  }
}
#login-button{
 width: 100%;
}
#submit-button-pass{
 width: 100%;
}

.video_palestrante {
  width: 556px;
  margin-left: 20px;
  float: left;
}
.video_palestrante.first {
  margin-left: 0px;
}
.form-block {
  color: #fff;
  font-weight: 400;
}
.field-label-3 {
  font-weight: 400;
}
.field-label-4 {
  font-weight: 400;
}
.submit-button-2 {
  width: 100%;
  margin-top: 20px;
  border-radius: 30px;
  background-color: #FFC200;
  color: #070707;
  font-size: 16px;
  font-weight: 700;
}
.text-field-3 {
  border-radius: 30px;
}
.text-field-4 {
  border-radius: 30px;
}
.select-field {
  border-radius: 30px;
  background-color: #fff;
}
.div-block-13 {
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
}
.videos {
  margin-top: 40px;
}
.columns-3 {
  margin-top: 40px;
}
.text-field-5 {
  border-radius: 30px;
}
.iframe-container {
  display: none;
}
.object1{
 position: absolute;
 z-index: -1;
 margin-top: -41px;
 left: -7%;
}
.object2{
 position: absolute;
 right: 65px;
 width: 170px;
 z-index: -1;
}
.object3{
 position: absolute;
 z-index: -1;
 margin-top: -120px;
 left: 1%;
 width: 192px;
}
// .section-6 {
//   min-height: 500px;
//   padding-top: 50px;
//   padding-bottom: 50px;
//   background-image: url('../src/assets/images/landing-page/bg_saber_imobiliario2x.png');
//   background-position: 0px 0px;
//   background-size: cover;
//   font-weight: 700;
// }
.div-block-14 {
  margin-top: 60px;
  margin-bottom: 49px;
}
.button-3 {
  width: 100%;
  border: 1px solid #fff;
  border-radius: 30px;
  background-color: rgba(56, 152, 236, 0);
  font-size: 16px;
  text-align: center;
}
.div-block-15 {
  min-width: 100px;
  float: right;
}
.text-block-8 {
  margin-top: 15px;
  color: #070707;
  font-weight: 700;
}
.text-block-9 {
  color: #070707;
  font-size: 16px;
  font-style: italic;
}
.text-block-10 {
  color: #ffffff;
  font-size: 16px;
  font-style: italic;
}
.text-small {
  width: 100%;
  color: #B1B1B1;
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-top: 16px;
  margin-left: 10px;
}
.invitation{
  color: #483E99;
  text-align: center;
  font-family: Rubik;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 53px;
}

.div-block-16 {
  position: relative;
  margin-top: 0px;
  margin-left: 0px;
  float: left;
}
.container-8 {
  margin-top: 21px;
}
.div-block-18 {
  width: 130px;
  float: left;
}
// .text-block-15 {
//   margin-top: 10px;
//   color: #483E99;
//   font-size: 30px;
//   font-weight: 900;
//   text-align: center;
// }

#admin-container{
  margin-top: 109px;

  .big-text{
    text-align: left;
  }

  .lead-total-count{
    margin-top: 157px;
    color: #483E99;
    text-align: right;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.16px;
    width: 100%;
  }
}

.admin-table{
  width: 100%;
  height: 500px;
  overflow-y: auto;

  thead{
    tr{
      th{
        background: #483E99;
        color:#FFF;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.12px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }

  tbody{
    tr{
      td{
        word-wrap: break-word;
        color: #5D5D5D;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.12px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      &.even{
        td{
          background: #F7F7F7 !important;
        }
      }

      &.odd{
        td{
          background: #FFF !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1130px) {
  .form-desktop{
    display: none;
  }
}

@media screen and (min-width: 1130px) {
  .form-mobile{
    display: none;
  }
}


.depoimentos-container{
  background: #D4D4D4;
  height: 100%;
  flex-shrink: 0;
  padding-bottom: 48px;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  &.white{
    padding-top: 100px;
    background: #FFF;
    margin-bottom: 400px;
    
    .texts-container{
      color: #483E99 !important;
    }
  }

  .depoimento-container{
    width: 100%;
    max-width: 50%;
    display: flex !important;
    justify-content: space-between;
    flex-direction: row !important;
    
    .corretor-image{
      position: relative;
      display: flex;

      img{
        width: 190px;
        // position: absolute;
        right: 0 !important;

        &.background{
          width: 250px;
        }
      }

      .aspas{
        width: 83px;

        img{
          width:83px !important;
        }
      }
    }

    .texts-container{
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      text-align: left;

      .texts{
        width: 493px;
        // height: 149px;
        flex-shrink: 0;
        color: #262626;
        font-family: Rubik;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        .purple-text{
          color: #483E99;
          font-family: Rubik;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 20px;
        }

        .blue-name{
          color: #483E99;
          font-family: Rubik;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}

.image-3 {
  margin-left: 20px;

  &.creci{
    width: 108px;
  }
}
.button-4 {
  display: none;
  width: 100%;
  padding: 20px 30px;
  border-radius: 40px;
  background-color: #FFC200;
  font-family: Rubik;
  color: #262626;
  font-size: 18px;
}
.div-block-19 {
  max-width: 600px;
  margin-top: 100px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.heading-7 {
  font-family: Rubik;
}
.div-block-20 {
  /*display: none;*/
  max-width: 1000px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #483E99;
  color: white;
}
.videos-carousel .slick-arrow {
 position: absolute;
 top: 50%;
 background: url(https://raw.githubusercontent.com/solodev/infinite-logo-carousel/master/images/arrow.svg?sanitize=true) center no-repeat;
 color: #fff;
 filter: invert(77%) sepia(32%) saturate(1%) hue-rotate(344deg) brightness(105%) contrast(103%);
 border: none;
 width: 2rem;
 height: 1.5rem;
 text-indent: -10000px;
 margin-top: 185px;
 z-index: 99;
}

.video-container{
  text-align: left;
  margin-bottom: 94px;
  @media screen and (max-width: 767px) {
    display: block;
  }
  .texts-container{

    .button-container{
      width: 144px;
      text-transform: uppercase;

      &.admin{
        width: 118px !important;
        text-transform: none;
      }

      &.inscrevase{
        width: 131px !important;
        text-transform: none;
      }
    }

    .button-container{
      width: 144px;
      text-transform: uppercase;
      margin-bottom: 6px;

      a{
        color: #FFF;
        text-align: center;
        font-family: Rubik;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.12px;
        width: 144px;
      }
    }

    .text{
      text-align: left;
      margin-bottom: 24px;

      .title{
        color: #483E99 !important;
        text-align: left;
        font-family: Rubik;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 8px;
      }
      
      .explanation{
        margin-bottom: 13px;
        overflow: hidden;
        color: #483E99;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        max-width: 575px;
      }

      .yellow-link{
        a{
          color: #FFC300;
          text-align: left;
          font-family: Rubik;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.16px;
          text-decoration: none;
        }
      }
    }
  }
}

.videos-carousel .slick-arrow.slick-next {
right: 300px;
transform: rotate(180deg);
}

.videos-carousel .slick-arrow.slick-prev {
left: 300px;
}
.logo-carousel .slick-arrow {
position: absolute;
top: 50%;
background: url(https://raw.githubusercontent.com/solodev/infinite-logo-carousel/master/images/arrow.svg?sanitize=true) center no-repeat;
color: #fff;
filter: invert(77%) sepia(32%) saturate(1%) hue-rotate(344deg) brightness(105%) contrast(103%);
border: none;
width: 2rem;
height: 1.5rem;
text-indent: -10000px;
margin-top: -16px;
z-index: 99;
}

.logo-carousel .slick-arrow.slick-next {
// right: -40px;
transform: rotate(180deg);
}

.logo-carousel .slick-arrow.slick-prev {
top: 80px;
}

.depoimentos-carousel .slick-arrow.slick-prev {
  // left: -40px;
  // top: 65px;
  top: 150px;
  }

.depoimentos-carousel .slick-arrow {
position: absolute;
top: 50%;
background: url(https://raw.githubusercontent.com/solodev/infinite-logo-carousel/master/images/arrow.svg?sanitize=true) center no-repeat;
color: #fff;
// filter: invert(77%) sepia(32%) saturate(1%) hue-rotate(344deg) brightness(105%) contrast(103%);
filter: invert(100%) sepia(64%) saturate(1%) hue-rotate(344deg) brightness(105%) contrast(103%);
border: none;
width: 2rem;
height: 1.5rem;
text-indent: -10000px;
margin-top: -16px;
z-index: 99;
}

.slick-arrow{
  &:hover{
    background: auto;
  }
  &:focus{
    background: auto;
  }
}

.depoimentos-carousel .slick-arrow.slick-next {
right: -40px;
transform: rotate(180deg);
}

.depoimentos-carousel .slick-arrow.slick-prev {
left: -30px;
}
.heading-8 {
  font-family: Rubik;
  font-weight: 600;
}
.columns-4 {
  padding: 20px;
}
.link-3 {
  margin-top: 13px;
  float: right;
  font-family: Rubik;
  font-size: 16px;
  text-align: right;
}
.button-5 {
  width: 100%;
  float: right;
  border-radius: 10px;
  background-color: #483E99;
  font-family: Rubik;
  text-align: center;
}
.column {
  font-family: Rubik;
}
.body-2 {
  font-family: Rubik;
}
.div-block-21 {
  width: 140px;
  float: right;
}
.div-block-22 {
  width: 140px;
  margin-right: 10px;
  float: right;
}
.div-block-22.ultimo_dia {
  margin-right: 0px;
}
.button-6 {
  width: 100%;
  border-radius: 10px;
  background-color: #FFC300;
  color: #262626;
  text-align: center;
}
.button-6.ultimo_dia {
  margin-left: 1px;
}
.section-7 {
  display: none;
}
.heading-9 {
  color: #ffa800;
}
.div-block-23 {
  margin-top: 146px;
}
.text-block-16 {
  color: #fff;
  font-size: 26px;
}
.imgBanner {
//  max-width: 220%;
  max-width: 400px;
 margin-top: 10%;
}
// .imgBanner2 {
// max-width: 250%;
// }
.iframe_videos{
 width: 100%;
 height: 308px;
 border-radius: 10px;
}
@media screen and (max-width: 991px) {
  .login {
      display: none;
 }
}
@media screen and (max-width: 767px) {

  .image-3 {
    width: 48px;

    &.creci{
      width: 30px !important;
    }
  }
  .navbar-collapse{
    position: absolute;
    top:72px;
    text-align: left;
    background: #FFF;
    box-shadow: 0 6px 3px 0 rgba(0, 0, 0, 0.15);
    // color: #FFF !important;

    .nav-link{
      border-bottom: 1px solid #000;
    }
  }

 .cityDiv {
 width: 100%;
}
.object1,.object2,.object3{
 display: none;
}
.tab-link-space-25{
 width: 100%;
}
.tab-link-space-25-2{
  width: 100%;
  border: none;
  background: white;
  color: white;
}
.tab-link-space-29{
 width: 100%;
}
.tab-link-space-21{
 width: 100%;
}
.videos-carousel .slick-arrow.slick-next {
 right: 14px;
}
.videos-carousel .slick-arrow.slick-prev {
 left: 14px;
}
.logo-carousel .slick-arrow.slick-next {
 right: -30px !important;
}
.logo-carousel .slick-arrow.slick-prev {
 left: 0px;
  top: 40px;
}
.container {
//  max-width: 250px;
}
.containerLogoCreci{
  max-width: 335px;
  margin: auto;
  padding-top: 21px;
  margin-top: 21px;
}
.iframe_videos{
 width: 100%;
 height: 290px;
 border-radius: 10px;
}
.imgBanner {
 max-width: 40% !important;
//  margin-left: 10%;
}
// .imgBanner2 {
//  max-width: 109%;
//  margin-top: -18%;
//  margin-left: -10%;
// }
 .day-block {
 width: 25%;
}
  .principal {
      min-height: 150px;
      margin-top: 60px;
 }
 .principal2 {
      min-height: 150px;
      margin-top: 60px;
 }
 .margin-footer {
margin-left: 0px !important;
}
.div-separator {
display: none;
}
.modal-content {
 width: 85% !important;
}
@media screen and (max-width: 1130px) {
.imgBanner {
 max-width: 85%;
 margin-left: 10%;
}
.imgBanner2 {
 max-width: 109%;
 margin-top: -18%;
 margin-left: -10%;
}
.object1,.object2,.object3{
 display: none;
}
.videos-carousel .slick-arrow.slick-next {
 right: 14px;
}
.videos-carousel .slick-arrow.slick-prev {
 left: 14px;
}
.logo-carousel .slick-arrow.slick-next {
 right: 0px;
 &:hover{
  background: #070707;
 }
}
.logo-carousel .slick-arrow.slick-prev {
 left: 30px;
 &:hover{
  background: #070707;
 }
}
.logo-carousel{
  .slick-list{
    margin-left: 30px;
  }
}
.tab-link-space-25{
 width: 100%;
}
.tab-link-space-25-2{
  width: 100%;
  border: none;
  background: white;
  color: white;
 }
.tab-link-space-29{
 width: 100%;
}
.tab-link-space-21{
 width: 100%;
}
.container {
//  max-width: 250px;
//  max-width: 330px;
 max-width: 100%;
 padding-left: 8px !important;
 padding-right: 8px !important;
 margin: 0 !important;
}
.containerLogoCreci{
 max-width: 335px;
 padding-top: 21px;
 margin-top: 21px;
 margin: auto;
}
.iframe_videos{
 width: 100%;
 height: 290px;
 border-radius: 10px;
}
.modal-content {
 width: 85% !important;
}
.day-block {
 width: 25%;
}
.margin-footer {
margin-left: 0px !important;
}
.div-separator {
display: none;
}
.cityDiv {
 width: 100%;
}
.div-block-2 {
 margin-top: 10px;
}
  .div-table-cell {
    display: block;
    margin-top: 14px;
 }
  .css-input.name-user{
      width: 100%;
 }
  .css-input.cpf-user{
      width: 100%;
 }
  .css-input.cnpj-user{
      width: 100%;
 }
  .css-input.creci-user{
      width: 100%;
 }
  .css-input.email-user{
      width: 100%;
 }
 .css-input.email-user2{
  width: 100%;
}
  .css-input.pass-user{
      width: 100%;
 }
  .css-input.pass2-user{
      width: 100%;
 }
  .css-input.phone-user{
      width: 100%;
 }
  .css-input.business-name-user{
      width: 100%;
 }
  .css-input.role-user{
      width: 100%;
 }
  .css-input.zipcode-user{
      width: 100%;
 }
  .css-input.city-user{
      width: 100%;
 }
  .css-input.state-user{
      width: 100%;
 }
 .css-input.isCorretor-user{
  width: 100%;
 }
 .text-small{
      width: 100%;
 }
  .div-block {
      min-width: 100px;
 }
 }
  .div-block-2 {
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
      text-align: center;
 }

  .heading-2 {
      margin-top: 0px;
 }
  .text-block-2 {
      text-align: center;
 }
  .div-block-4 {
      padding-right: 0px;
 }
  .tab-pane-tab-1-2 {
      padding-right: 15px;
      padding-left: 15px;
 }
  .tab-pane-tab-2-2 {
      padding-right: 15px;
      padding-left: 15px;
 }
  .tab-pane-tab-3-2 {
      padding-right: 15px;
      padding-left: 15px;
 }
  .tab-pane-tab-4-2 {
      padding-right: 15px;
      padding-left: 15px;
 }
  .tab-pane-tab-5-2 {
      padding-right: 15px;
      padding-left: 15px;
 }
  .tab-link-01-12 {
      margin-left: 0px;
 }
  .tab-link-02-12 {
      margin-left: 0px;
 }
  .tab-link-03-12 {
      margin-left: 0px;
 }
  .tab-link-04-12 {
      margin-left: 0px;
 }
  .tabs-2 {
      margin-top: 15px;
 }
  .float_left {
      display: none;
 }
  .text-block-4 {
      padding-right: 10px;
      padding-left: 10px;
 }
  .cadastro {
      padding-right: 0px;
      padding-left: 0px;
 }
  .creci_parceiro {
      width: 100px;
      margin-left: 0px;
 }
  .div-block-7 {
      width: 100%;
      padding-left: 5px;
 }
  .columns-2 {
      margin-top: 0px;
 }
  .depoimentos {
      margin-top: 20px;
 }
  .text-block-6 {
      text-align: center;
 }
  .div-block-9 {
      position: static;
      width: 130px;
      margin-right: auto;
      margin-left: auto;
      float: none;
      clear: both;
 }
  .float_right {
      display: none;
 }
  .div-block-15 {
      margin-right: 10px;
 }
  .div-block-16 {
      position: static;
      width: 130px;
      margin-top: 0px;
      margin-right: auto;
      margin-left: auto;
      float: none;
 }
  .image-2 {
      max-width: 100%;
 }
  .text-block-10 {
      text-align: center;
 }
  .text-block-11 {
      text-align: center;
 }
  .text-block-12 {
      text-align: center;
 }
  .text-block-13 {
      text-align: center;
 }
  .text-block-14 {
      text-align: center;
 }
  .heading-3 {
      text-align: center;

      font-size: 26px !important;
      line-height: 24px !important;
      margin-top: 10px !important;
      font-weight: bolder !important;
      margin-bottom: 30px !important;
 }
  .div-block-17 {
      display: none;
      text-align: center;
 }
  .div-block-18 {
      width: 130px;
      margin-right: auto;
      margin-left: auto;
 }
  .heading-4 {
      text-align: center;
 }
  .heading-5 {
      text-align: center;
 }
  .heading-6 {
      text-align: center;
 }
  .icon {
      color: #070707;
 }
  .heading-7 {
      margin-left: 20px;
 }
  .div-block-20 {
      margin-right: 10px;
      margin-left: 11px;
 }
  .heading-8 {
      margin-top: 0px;
      font-size: 14px;
      text-align: center;
 }
  .button-5 {
      width: 100%;
      text-align: center;
 }
  .div-block-21 {
      position: relative;
      width: 100%;
      max-width: 100%;
      margin-right: auto;
      margin-left: auto;
      float: left;
 }
  .div-block-22 {
      width: 100%;
      max-width: 100%;
      margin-top: 9px;
      margin-right: auto;
      margin-left: auto;
      float: left;
 }
  .button-6 {
      width: 100%;
      text-align: center;
 }
}
.w-slider-nav {
}
.w-slider-dot.w-active {
  width: 10px;
  height: 10px;
  background: #d0222f;
}
.w-slider-dot{
  background:#cacaca;
  width: 10px;
  height: 10px;
  margin-left:10px;
}




.button-certificado {
 width: 200px;
 border-radius: 10px;
 background-color: #483E99;
 font-family: Rubik;
 text-align: center;
 color: #fff;
}


// @font-face {
//   font-family: 'Inter';
//   src: url('../src/assets/fonts/Inter-Bold.ttf') format('truetype');
//   font-weight: 700;
//   font-style: normal;
//   font-display: auto;
// }
// @font-face {
//   font-family: 'Inter';
//   src: url('../src/assets/fonts/Inter-Regular.ttf') format('truetype');
//   font-weight: 400;
//   font-style: normal;
//   font-display: auto;
// }
// @font-face {
//   font-family: 'Inter';
//   src: url('../src/assets/fonts/Inter-SemiBold.ttf') format('truetype');
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Inter';
//   src: url('../src/assets/fonts/Inter-Black.ttf') format('truetype');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }






.slick-slider {
margin: 0px 20px;
}

.logo-carousel {
overflow: inherit;
padding-top: 21px;
}

.depoimentos-carousel {
  overflow: inherit;
  padding-top: 51px;
  }

.slick-slide img {
width: 70%;
}

.slick-track::before,
.slick-track::after {
display: table;
content: '';
}

.slick-track::after {
clear: both;
}

.slick-track {
padding: 1rem 0;
}

.slick-loading .slick-track {
visibility: hidden;
}

.slick-slide.slick-loading img {
display: none;
}

.slick-slide.dragging img {
pointer-events: none;
}

.slick-loading .slick-slide {
visibility: hidden;
}

.programacao-big-title{
  color: #483E99;
  text-align: left;
  font-family: Rubik;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 28px;
}

/* Media Queries */

.row {
  width: 100%;
  overflow: hidden;
}  
/* The Modal (background) */
.modal {
display: none; /* Hidden by default */
position: fixed; /* Stay in place */
z-index: 99; /* Sit on top */
padding-top: 100px; /* Location of the box */
left: 0;
top: 0;
width: 100%; /* Full width */
height: 100%; /* Full height */
overflow: auto; /* Enable scroll if needed */
background-color: rgb(0,0,0); /* Fallback color */
background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
background-color: #fefefe;
margin: auto;
padding: 20px;
border: 1px solid #888;
width: 500px;
border-radius: 30px;
}

/* The Close Button */
.close {
color: #aaaaaa;
float: right;
font-size: 28px;
font-weight: bold;
}

.close:hover,
.close:focus {
color: #000;
text-decoration: none;
cursor: pointer;
}